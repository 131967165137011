import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Navbar from './Nabvar';

import Home from '../pages/Home';
import About from '../pages/About';
import Contact from '../pages/Contact';

function App () {
    return(
        <React.Fragment>
            <BrowserRouter>
                <Navbar />
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/about" component={About} />
                    <Route exact path="/contact" component={Contact} />
                </Switch>
            </BrowserRouter>
        </React.Fragment>
    )
}

export default App;