import React from 'react';

function Contact () {
    return(
        <React.Fragment>
            <h2 className="mt-4 ml-4">Página de Contacto</h2>
            <div className="mt-4 ml-4 mr-4 mb-3">
                <label for="exampleFormControlInput1" className="form-label">Email address</label>
                <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="name@example.com" />
            </div>
            <div className="ml-4 mr-4 mb-3">
                <label for="exampleFormControlTextarea1" className="form-label">Example textarea</label>
                <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
            </div>
        </React.Fragment>
    )
}

export default Contact;